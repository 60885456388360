import React from "react"

import Index from "../../components/index"
import SEO from "../../components/other/seo"
import Container from "../../components/layout/container";
import {Link} from "gatsby";
import {
  Collapsible,
  CollapsibleTitle,
  CollapsibleContent,
} from "../../components/elements/collapsible";
import MagnifiedViewCrystalImage from "../../components/images/magified-view-crystal";
import {FaChevronRight} from "react-icons/all";
import Video from "../../components/elements/video";
import DetergentImage from "../../components/images/detergent";
import MicroSure from "../../components/elements/micro-sure";

const DetergentPage = () => (
  <Index>
    <SEO title="microSURE® All Purpose Laundry Detergent" />
    <Container id="product-overview-banner">
      <h1>microSURE® All Purpose Laundry Detergent</h1>
    </Container>
    <Container id="product-details">
      <div className="left">
        <DetergentImage />
      </div>
      <div className="right">
        <h1>
          microSURE® All Purpose<br />
          Laundry Detergent
        </h1>
        <p>
          <b>NDC Identification:</b> XXXXX<br />
          <b>EPA Registration Number:</b> #1839‐83
        </p>
        <hr />
        <p>Coming Soon! <MicroSure /> all purpose laundry detergent safeguards your laundry up to 75 additional washes by mechanically killing germs as textiles are touched or worn.</p>
        <p>While harmful bacteria can effortlessly attach to textiles, the <MicroSure /> all purpose laundry detergent forms a covalent bond to fabric fibers and begins killing germs on contact after the laundry has dried.</p>
        <p>
          <Link to="/order" className="button bordered">Order</Link>
        </p>
        <hr />
        <Collapsible>
          <CollapsibleTitle>Additional Benefits</CollapsibleTitle>
          <CollapsibleContent>
            <p>
              Safest EPA toxicity category IV rating for human exposure<br />
              Can last up to 75 additional washes<br />
              5‐year shelf life
            </p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Available Sizes</CollapsibleTitle>
          <CollapsibleContent>
            <p><em>Coming Soon</em></p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>How to Apply</CollapsibleTitle>
          <CollapsibleContent>
            <p>Check our <Link to="/how-to">How-To</Link> section for detailed instructions on how to use the products.</p>
          </CollapsibleContent>
        </Collapsible>
        <p>&nbsp;</p>
      </div>
    </Container>
    <Container>
      <div className="product-info-thumb">
        <div className="left">
          <MagnifiedViewCrystalImage />
        </div>
        <div className="right">
          <h3>
            <i><FaChevronRight /></i><br />
            Magnified view of crystal spiked<br />
            barrier that kills harmful viruses<br />
            and bacteria on contact.
          </h3>
        </div>
      </div>
    </Container>
    <Container id="product-training-videos">
      <div className="left" />
      <div className="right">
        <h2>Training</h2>
        <p>How to apply hand sanitizer</p>
        <Video title="Video" videoID="HtZNk60ntKw" />
      </div>
    </Container>
  </Index>
)

export default DetergentPage
